@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,700&display=swap);

/* Font Weight */
.w-1{
  font-weight: 100!important;
}
.w-2{
  font-weight: 200!important;
}
.w-3{
  font-weight: 300!important;
}
.w-4{
  font-weight: 400!important;
}
.w-5{
  font-weight: 500!important;
}
.w-6{
  font-weight: 600!important;
}
.w-7{
  font-weight: 700!important;
}

.m-0{
  margin: 0!important;
}

/* Top and Bottom margin*/
.mt-1{
  margin-top: 10px;
}

.mt-2{
  margin-top: 20px;
}

.mt-3{
  margin-top: 30px;
}

.mt-4{
  margin-top: 40px;
}

.mt-5{
  margin-top: 50px;
}

.mb-1{
  margin-bottom: 10px;
}

.mb-2{
  margin-bottom: 20px;
}

.mb-3{
  margin-bottom: 30px;
}

.mb-4{
  margin-bottom: 40px;
}

.mb-5{
  margin-bottom: 50px;
}

/* Left and right margin */
.ml-1{
  margin-left: 10px;
}

.ml-2{
  margin-left: 20px;
}

.ml-3{
  margin-left: 30px;
}

.ml-4{
  margin-left: 40px;
}

.ml-5{
  margin-left: 50px;
}

.mr-1{
  margin-right: 10px;
}

.mr-2{
  margin-right: 20px;
}

.mr-3{
  margin-right: 30px;
}

.mr-4{
  margin-right: 40px;
}

.mr-5{
  margin-right: 50px;
}

.m-auto{
  margin: auto;
}

/* padding */
.p-0{
  padding: 0!important;
}

.p-1{
  padding: 10px;
}

.p-2{
  padding: 20px;
}

.p-3{
  padding: 30px;
}

.p-4{
  padding: 40px;
}

.p-5{
  padding: 50px;
}

.p-auto{
  padding: auto;
}

.c-pointer{
  cursor: pointer;
}
body {
  background-color: #fffeed;
  background-image: url(/static/media/bg-light.4cb746ba.png);
  background-size: cover;
  background-repeat: repeat-y;
  font-family: 'poppins';
}

.main {
  min-height: 100vh;
}

a,
a:hover {
  text-decoration: none;
}

.login-container {
  min-height: 100vh;
}

.login-container div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.login-card {
  background: #fbe29c;
  box-shadow: 2px 0 5px rgba(179, 179, 179, 0.25),
    -2px 0 5px rgba(179, 179, 179, 0.25), 0 -2px 5px rgba(179, 179, 179, 0.25),
    0 2px 5px rgba(179, 179, 179, 0.25);
  border-radius: 17px;
  padding: 5%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.bw-button {
  background: #fffeed !important;
  box-shadow: 1px 1px 4px rgba(155, 155, 155, 0.25);
  border: none;
  border-radius: 10px !important;
  padding: 10px;
  /* width: 100%; */
  margin: 5px;
  font-size: 0.8em;
  font-weight: bold;
  color: black !important;
}

.help-button {
  background: #fbe29c !important;
  box-shadow: 1px 1px 4px rgba(155, 155, 155, 0.25);
  border: none;
  border-radius: 10px !important;
  padding: 10px;
  /* width: 100%; */
  margin: 5px;
  font-size: 0.8em;
  font-weight: bold;
}

.menu-button {
  width: 100%;
}

.bw-button:focus {
  outline: none;
}

.bw-button:hover {
  background: #fdfce1 !important;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease;
}

.overlay {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.create-bucket-prompt {
  background: #ffffff;
  border-radius: 40px;
  width: 100%;
  padding: 2%;
  margin: 2%;
}

.create-bucket-prompt:focus {
  outline: none;
}

.bucket-image {
  width: 90%;
  margin: 3% 0;
}

.single-bucket-container {
  position: relative;
}

.bucket-details {
  position: absolute;
  top: 67%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #fbe296 !important;
}

.help-bucket-details {
  position: absolute;
  top: 67%;
  left: 50%;
  -webkit-transform: translate(-60%, -50%);
          transform: translate(-60%, -50%);
  color: #fbe296 !important;
}

.avatar {
  border-radius: 50%;
  width: 50%;
}

.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.drawer {
  position: fixed;
  height: 90vh;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #fffeed;
  border-radius: 15px;
  z-index: 10;
  right: 10%;
  top: 5%;
  padding: 2%;
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.4s;
  animation-name: animateright;
  animation-duration: 0.4s;
}

.drawer-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.bucket-open {
  background: #fffeed;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  width: 100%;
  z-index: 9;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.wishes-container {
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: none;
}

.wish {
  background: #fbe296;
  height: 100%;
  border-radius: 10px;
  width: 99%;
}

.content-box {
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2%;
}

.donate-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.donate-title img {
  width: 50%;
}

.donate-card {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  padding: 4% 4%;
  background: #fbe296;
}

.bucket-title {
  display: flex;
  justify-content: space-between;
  font-size: 0.75em !important;
}

.home-bucket {
  font-size: 0.75em !important;
}

.create-bucket-card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  background: #fffeed;
  padding: 2%;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  width: 100%;
  min-height: 300px;
  z-index: 9;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

input,
textarea {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #b9aa00;
  padding: 1%;
  width: 80%;
}

input:focus,
textarea:focus {
  outline: none;
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  min-height: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.not-found > img {
  width: 40%;
  margin-bottom: 30px;
}

#brand {
  width: 25%;
  /* position: absolute; */
}

.content {
  margin-top: 12% !important;
}

#login-logo {
  width: 50%;
}

.wish-action-icon {
  font-size: medium !important;
}

.edit-wish {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.bucket-actions {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.close-button:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  transition: 0.3s;
}

.bucket-info {
  padding: 2%;
  position: absolute;
  right: 20%;
  min-height: 75%;
  width: 30%;
  background: #fbe296;
  z-index: 1;
  box-shadow: -5px 5px 5px 0px #00000030;
  border-radius: 10px;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bucket-info .details div {
  display: flex;
  justify-content: space-between;
}

.text-link:hover {
  text-decoration: underline;
  transition: 0.2s;
}

/* The Modal (background) */
.bw-dialog {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding: 2% 0; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.bw-dialog-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fefefe;
  margin: auto;
  padding: 1% 0;
  border: 1px solid #888;
  border-radius: 10px;
  width: 65%;
  min-height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* The Close Button */
.bw-dialog-close {
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.bw-dialog-close:hover,
.bw-dialog-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  transition: 0.3s;
}

.bw-dialog-header {
  margin: 2% 4%;
}

.bw-dialog-body {
  margin: 0 4%;
  flex: 4 1;
}

.bw-dialog-footer {
  margin: 2% 4%;
  /* flex: 1; */
  display: flex;
  align-items: flex-end;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  margin-bottom: -80px;
}

.sendButton {
  background-color: #fbe296 !important;
}

.bucket-top-actions {
  float: right;
}

.feedback-button {
  position: fixed !important;
  right: 5%;
  bottom: 5%;
}

/* Mobile screens */
@media only screen and (max-width: 768px) {
  * {
    font-size: small;
  }

  .bucket-top-actions {
    display: flex;
    justify-content: flex-end;
    float: initial;
  }

  .top-bar {
    margin-bottom: 20px;
  }

  .bucket-info {
    width: 75%;
    right: 4%;
    min-height: 50%;
    padding: 4%;
  }

  .create-bucket-card {
    width: 100%;
    /* min-height: 100vh; */
    justify-content: center;
    border-radius: 0;
  }

  .create-bucket-prompt {
    margin: auto !important;
  }

  .single-bucket-container {
    width: 75%;
    margin: auto;
  }

  .bw-dialog-content {
    padding: 5% 0;
    width: 100%;
    border-radius: 0;
  }

  .bw-dialog {
    padding: 0 !important;
  }

  .wish {
    text-align: start;
    padding: 2% !important;
  }

  .wish-actions {
    display: flex;
  }

  .donate-content {
    margin-top: 5px;
  }

  .donate-card {
    margin-top: 5px;
  }

  .help-bucket {
    width: 75%;
    margin: auto;
  }

  .login-container {
    text-align: center;
  }

  .login-container div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  #brand {
    width: 30%;
  }

  .bucket-title {
    justify-content: center;
  }

  .drawer {
    position: fixed;
    height: 90vh;
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #fffeed;
    border-radius: 15px;
    z-index: 10;
    right: 1%;
    top: 1%;
    padding: 2%;
  }

  .overlay {
    padding: 0 !important;
  }

  .bw-dialog-header {
    display: block;
  }
}



