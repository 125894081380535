
/* Font Weight */
.w-1{
  font-weight: 100!important;
}
.w-2{
  font-weight: 200!important;
}
.w-3{
  font-weight: 300!important;
}
.w-4{
  font-weight: 400!important;
}
.w-5{
  font-weight: 500!important;
}
.w-6{
  font-weight: 600!important;
}
.w-7{
  font-weight: 700!important;
}

.m-0{
  margin: 0!important;
}

/* Top and Bottom margin*/
.mt-1{
  margin-top: 10px;
}

.mt-2{
  margin-top: 20px;
}

.mt-3{
  margin-top: 30px;
}

.mt-4{
  margin-top: 40px;
}

.mt-5{
  margin-top: 50px;
}

.mb-1{
  margin-bottom: 10px;
}

.mb-2{
  margin-bottom: 20px;
}

.mb-3{
  margin-bottom: 30px;
}

.mb-4{
  margin-bottom: 40px;
}

.mb-5{
  margin-bottom: 50px;
}

/* Left and right margin */
.ml-1{
  margin-left: 10px;
}

.ml-2{
  margin-left: 20px;
}

.ml-3{
  margin-left: 30px;
}

.ml-4{
  margin-left: 40px;
}

.ml-5{
  margin-left: 50px;
}

.mr-1{
  margin-right: 10px;
}

.mr-2{
  margin-right: 20px;
}

.mr-3{
  margin-right: 30px;
}

.mr-4{
  margin-right: 40px;
}

.mr-5{
  margin-right: 50px;
}

.m-auto{
  margin: auto;
}

/* padding */
.p-0{
  padding: 0!important;
}

.p-1{
  padding: 10px;
}

.p-2{
  padding: 20px;
}

.p-3{
  padding: 30px;
}

.p-4{
  padding: 40px;
}

.p-5{
  padding: 50px;
}

.p-auto{
  padding: auto;
}

.c-pointer{
  cursor: pointer;
}